<template>
  <div class="toolbar">
    <a href="/" class="toolbar-logo">
      <img :alt="assc.name" :src="assc.logo" class="img-fluid" />
    </a>
    <h1 v-if="isTitleVisible">{{ title }}</h1>
    <div class="call-us">
      <div>SORUNUZ MU VAR?</div>
      <a :href="`tel:${assc.phone}`">{{ assc.phone }}</a>
    </div>
  </div>
</template>

<script>
function getAssc(hostname) {
  switch (hostname) {
    case "uyelik.akib.org.tr":
      return {
        name: "AKİB",
        logo: require("@/assets/logo-akib.svg"),
        phone: "0 (324) 325 37 37"
      };
    case "uyelik.baib.gov.tr":
      return {
        name: "BAİB",
        logo: require("@/assets/logo-baib.svg"),
        phone: "0 (242) 505 10 00"
      };
    case "uyelik.denib.gov.tr":
      return {
        name: "DENİB",
        logo: require("@/assets/logo-denib.svg"),
        phone: "0 (258) 274 66 88"
      };
    case "uyelik.daib.org.tr":
      return {
        name: "DAİB",
        logo: require("@/assets/logo-daib.svg"),
        phone: "0 (442) 214 11 84"
      };
    case "uyelik.dkib.org.tr":
      return {
        name: "DKİB",
        logo: require("@/assets/logo-dkib.svg"),
        phone: "0 (462) 326 16 01"
      };
    case "uyelik.eib.org.tr":
      return {
        name: "EİB",
        logo: require("@/assets/logo-eib.svg"),
        phone: "0 (232) 488 60 00"
      };
    case "uyelik.gaib.org.tr":
      return {
        name: "GAİB",
        logo: require("@/assets/logo-gaib.svg"),
        phone: "0 (342) 211 05 46"
      };
    case "uyelik.hib.org.tr":
      return {
        name: "HİB",
        logo: require("@/assets/logo-hib.svg"),
        phone: "0 (212) 454 01 00"
      };
    case "uyelik.iib.org.tr":
      return {
        name: "İİB",
        logo: require("@/assets/logo-iib.svg"),
        phone: "0 (212) 454 05 00"
      };
    case "uyelik.immib.org.tr":
      return {
        name: "İMMİB",
        logo: require("@/assets/logo-immib.svg"),
        phone: "0 (212) 454 00 00"
      };
    case "uyelik.itkib.org.tr":
      return {
        name: "İTKİB",
        logo: require("@/assets/logo-itkib.svg"),
        phone: "0 (212) 454 02 00"
      };
    case "uyelik.kib.org.tr":
      return {
        name: "KİB",
        logo: require("@/assets/logo-kib.svg"),
        phone: "0 (454) 216 24 26"
      };
    case "uyelik.oaib.org.tr":
      return {
        name: "OAİB",
        logo: require("@/assets/logo-oaib.svg"),
        phone: "0 (312) 447 27 40"
      };
    case "uyelik.uib.org.tr":
      return {
        name: "UİB",
        logo: require("@/assets/logo-uib.svg"),
        phone: "0 (224) 219 10 00"
      };
    case "localhost":
      return {
        name: "UİB",
        logo: require("@/assets/logo-uib.svg"),
        phone: "0 (224) 219 10 00"
      };
    default:
      return {
        name: "TİM",
        logo: require("@/assets/logo-tim.svg"),
        phone: "444 0 846"
      };
  }
}

export default {
  props: ["title"],
  data() {
    const assc = getAssc(window.location.hostname.toLowerCase());

    return {
      assc
    };
  },
  computed: {
    isTitleVisible() {
      return this.title != "" && this.title != null;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../scss/variables";
@import "../scss/mixins";

.toolbar {
  background: #fff;
  //border-top: solid 4px $color-brand-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 100;

  @include box-shadow();

  .toolbar-logo {
    img {
      max-height: 50px;
    }
  }

  h1 {
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    text-transform: uppercase;
    font-weight: $font-weight-extra-bold;
  }

  .call-us {
    color: #505050;
    font-size: 13px;
    text-align: right;
    line-height: 13px;

    a {
      font-size: 30px;
      line-height: 32px;
      color: #000000;
      text-decoration: none;
      font-weight: 500;
      display: block;
    }
  }
}
</style>
